import React from 'react'

function Header() {
 
  return (
<>




</>
  )
}

export default Header





// import React from 'react'
// import './Header.css'

// import { BsFillBellFill, BsJustify, BsFillEnvelopeFill, BsPersonCircle, BsSearch } from 'react-icons/bs'

// function Header({OpenSidebar}) {
//   return (
//     <div>
//          <header className='header'>
//       <div className="menu-icon">
//         <BsJustify className='icon' onClick={OpenSidebar}/>
//       </div>
//       <div className="header-left">
//         <BsSearch className='icon' />
//       </div>
//       <div className="header-right">
//         <BsFillBellFill className='icon' />
//         <BsFillEnvelopeFill className='icon' />
//         <BsPersonCircle className='icon' />

//       </div>

//     </header>

//     </div>
//   )
// }

// export default Header