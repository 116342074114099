// import React from 'react'
// import './Login.css'
// import { FaUser } from "react-icons/fa";
// import { FaLock } from "react-icons/fa6";

// function Login() {
//   return (
//     <div className='wrapper'>
//       <form action="">
//         <h1>SUGAR CUBE</h1><h1>DELIGHTS</h1>
          
//         <div className="input-box">
//           <input type="text" placeholder='Username'  required/>
//           <FaUser className='icon'/>
//         </div>
//         <div className="input-box">
//           <input type="password" placeholder='Password'  required/>
//           <FaLock className='icon'/>
//         </div>
//         <button type='submit'>Login</button>
//       </form>


//     </div>
//   )
// }

// export default Login






// model 1

import React from 'react'
import './Login.css'
import { Camera, CameraResultType } from '@capacitor/camera';
import { FaHamburger } from "react-icons/fa";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Axios } from 'axios';
import { Link } from 'react-router-dom';


function Login() {
  

const takePicture = async () => {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: true,
    resultType: CameraResultType.Uri
  });

  // image.webPath will contain a path that can be set as an image src.
  // You can access the original file using image.path, which can be
  // passed to the Filesystem API to read the raw data of the image,
  // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
  var imageUrl = image.webPath;
  let imageElement=document.getElementById('imageElement')
  // Can be set to the src of an image now
  imageElement.src = imageUrl;
};
  return (
    <>
   <div className='page'>
    <div className='cover'>
        <h1>Sugar <FaHamburger/> Cube</h1>  <h1>Delights</h1>
        <div className='underline'></div>
        <input className='login-inputs' type="text" placeholder='Username' />
        <input className='login-inputs' type="password" placeholder='Password' />
        
        <Link to="/main" className='login-btn'>Login</Link>
        {/* <button onClick={()=>takePicture()}> oopen Camera</button>
        <img id='imageElement' height='50'width='50'/> */}
        
        <div className="alt-login">
            <div className="facebook"></div>
            <div className="google"></div>
        </div>


    </div>
    </div>
    </>
  )
}

export default Login








