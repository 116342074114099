import React from 'react'
import './Allmenu.css'
function Allmenu() {
  return (
    <>
<div className="card-container">
    <div className="card">
        <img src="https://i.postimg.cc/0yGshvC4/pexels-valeria-boltneva-1199960.jpg" alt="" />
        <div className="card-content">
            <h3>Burger</h3>
            <p>Chicken,Cheese</p>
        </div>
    </div>

    <div className="card">
        <img src="https://i.postimg.cc/0yGshvC4/pexels-valeria-boltneva-1199960.jpg" alt="" />
        <div className="card-content">
            <h3>jh</h3>
            <p>Lorem ipsuugiat?</p>
        </div>
    </div>

    <div className="card">
        <img src="https://i.postimg.cc/0yGshvC4/pexels-valeria-boltneva-1199960.jpg" alt="" />
        <div className="card-content">
            <h3>jh</h3>
            <p>Lorem ipsuugiat?</p>
        </div>
    </div>

    <div className="card">
        <img src="https://i.postimg.cc/0yGshvC4/pexels-valeria-boltneva-1199960.jpg" alt="" />
        <div className="card-content">
            <h3>jh</h3>
            <p>Lorem ipsuugiat?</p>
        </div>
    </div>

    <div className="card">
        <img src="https://i.postimg.cc/0yGshvC4/pexels-valeria-boltneva-1199960.jpg" alt="" />
        <div className="card-content">
            <h3>jh</h3>
            <p>Lorem ipsuugiat?</p>
        </div>
    </div>

    <div className="card">
        <img src="https://i.postimg.cc/0yGshvC4/pexels-valeria-boltneva-1199960.jpg" alt="" />
        <div className="card-content">
            <h3>jh</h3>
            <p>Lorem ipsuugiat?</p>
        </div>
    </div>

    
</div>
    </>
  )
}


export default Allmenu