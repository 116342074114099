import React from 'react'
import { product } from '../Main_page/Data'
import './Product.css'
import { Link } from 'react-router-dom'

function Product() {
    
    return (
        <>
            <section className='product' id='products'>
                <h3 className='product-heading' >Our <span>Menu</span></h3>

                <div className='product-box'>
                  {
                  product.map((item,index)=>(
                    <div className="boxx">
                        <div className="product-icons">
                   
                            <a href="https://whatsform.com/ArHmGc" className='fa-brands fa-whatsapp'></a>
                            {/* <a href="#" className='fas fa-shopping-cart'></a> */}
                            <a href="#" className='fas fa-heart'></a>
                            <a href="tel:+919846358897" className="fa-solid fa-phone"></a>
                        </div>
                        <div className="image">
                            <img src={item.img} alt="" />
                        </div>
                        <div className="product-content">
                            <h3>{item.name}</h3>
                        
                            
                            <div className="price">{item.price}</div>
                       
                        </div>
                        
                    </div>
                    
                  ))
                  }
                  <Link to={'https://indigo-kudu-mp4qplpkbquyk0n2.builder-preview.com/'}>
                  <button className='viewall-btn'>View all</button>
                  </Link>
               
                </div>
                
            </section>
        </>
    )
}

export default Product

