import React from 'react'

function Payment() {
  
  return (
    
    <>

  
      
    </>
  )
}

export default Payment


   