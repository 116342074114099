import React from 'react'
import './Addproduct.css'


function Addproduct() {
  return (
    <>
    
<div class="form">

      <div class="input-container ic1">
        <input id="" class="input" type="text" placeholder="Image URL" />
      </div>

      <div class="input-container ic2">
        <input id="" class="input" type="text" placeholder="Product Name" />
      </div>

      <div class="input-container ic2">
        <input id="" class="input" type="text" placeholder="Product Price" />
      </div>

      <button type="text" class="submit">submit</button>
    </div>
</> 

  )
}

export default Addproduct

