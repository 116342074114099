import React from 'react'
import './Menu.css'
import {menu} from '../Main_page/Data'
import { Link } from 'react-router-dom'


function Menu() {

    return (
        <>
            <section className='menu' id='menu'>
                <h1 className='menu-heading'>
                    Our <span>Best-selling</span> Items
                </h1>
                <div className='menu-box'>
                    {
                        menu.map((item,index)=>(
                            
                            <div className='box'>
                                <Link to={'https://indigo-kudu-mp4qplpkbquyk0n2.builder-preview.com/'}>
                                <img src={item.img} alt="" />
                                </Link>
                               
                                <h3>{item.name}</h3>
                                <div className='menu-price'>
                                {item.price} 
                                </div>
                            
                            </div>
                        ))
                    }
                </div>

            </section>
        </>
    )
}

export default Menu

