import cartImage1 from '../Assets/cartImage1.jpg'
import cartImage2 from '../Assets/cartImage2.jpg'
import cartImage3 from '../Assets/cartImage3.jpg'

// 
import burg1 from '../Assets/burg.png'
import scoop from '../Assets/ice cream scoop.webp'
import juice from '../Assets/fresh juice.jpg'
import menuQrCode from '../Assets/Menu QR code.png'

import burg2 from '../Assets/doubleburg.png'
import burg3 from '../Assets/zingerburg.jpg'
import fries1 from '../Assets/fries.png'
import shake1 from '../Assets/choco shake.png'
import shake2 from '../Assets/pista shake.png'
import shake3 from '../Assets/strawshake.jpg'
import shake4 from '../Assets/vanilla shake.png'
import shake5 from '../Assets/milkshake-set.jpg'






const cart =[
    {
        img: cartImage1,
    },
    {
        img: cartImage2,
    },
    {
        img: cartImage3,
    },
];
const menu =[
    {
        img: burg1,
        name:'Burger',
        price:'₹ 110 Onwards'
    },

    {
        img: fries1,
        name:'Fries',
        price:'₹ 100 Onwards'
    },
  
    {
        img: shake5,
        name:'Shakes',
        price:'₹ 100 Onwards'
    },
   
    {
        img: scoop,
        name:'Scoops',
        price:'₹ 50 Onwards'
    },
    {
        img: juice,
        name:'Fresh juice',
        price:'₹ 80 Onwards'
    },
    {
        img: menuQrCode,
        name:'SCAN TO VIEW MENU',

    }

];

const product=[
    {
        img: burg2,
        name: 'XXX Cheese burger',
        price:'230 ₹'
    },

    {
        img: burg3,
        name: 'Zinger Cheese burger',
         price:'170 ₹'
    },
    {
        img: shake1,
        name: 'Chocolate Shake with ice cream',
         price:'120 ₹'

    },

    {
        img: shake2,
         name: 'Pistah Shake',
          price:'110 ₹'
    },
    {
        img: shake3,
        name: 'Strawberry Shake',
         price:'110 ₹'

    },
    
    {
        img: shake4,
         name: 'Vanilla Shake',
          price:'100 ₹'
    },
]

export {cart,menu,product};



